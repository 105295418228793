import React, { useState, useEffect, useRef } from 'react';
import api from '../../config/Api';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon, getMinusIcon, getPlusIcon } from '../../helper/SvgHelper';
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "../../styles/custom.css";


const AnalyticaSampleSubmissionForm = ({selectedRow,  setSelectedRow, setShowSubmissionFormDialog, sampleSubmissionList, setSampleSubmissionList, downloadSampleSubmissionForm}) => {

    const responseMsgSectionRef = useRef(null);
    const bottomDivRef = useRef(null);

    const [productCategoryTests, setProductCategoryTests] = useState(null);
    const [loading, setLoading] = useState(false);

    // Submission template
    const [historicalSubmissionId, setHistoricalSubmissionId] = useState(null);
    const [submissionTemplateId, setSubmissionTemplateId] = useState(null);
    const [submissionTemplateList, setSubmissionTemplateList] = useState(null);

    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [showResponseMsg, setShowResponseMsg] = useState(false);

    // Client Details
    const [companyName, setCompanyName] = useState(null);
    const [clientReference, setClientReference] = useState(null);
    const [address, setAddress] = useState(null);
    const [phone, setPhone] = useState(null);
    const [quoteNo, setQuoteNo] = useState(null);
    const [orderNo, setOrderNo] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [email, setEmail] = useState(null);
    const [submitter, setSubmitter] = useState(null);
    const [submitterEmail, setSubmitterEmail] = useState([]);
    const [invoiceTo, setInvoiceTo] = useState(null);
    const [additionalComments, setAdditionalComments] = useState(null);
    // Special from other client
    const [reportToEmail, setReportToEmail] = useState(null);

    // Sample Information
    const [sampleName, setSampleName] = useState(null);
    const [batchNo, setBatchNo] = useState(null);
    const [productionDate, setProductionDate] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [manufacturer, setManufacturer] = useState(null);
    const [approvalNo, setApprovalNo] = useState(null);
    const [originPlace, setOriginPlace] = useState(null);
    const [importer, setImporter] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [weightUnit, setWeightUnit] = useState(null);
    const [sampleColor, setSampleColor] = useState(null);
    const [sampleForm, setSampleForm] = useState(null);
    const [unitId, setUnitId] = useState(null);
    const [packingMode, setPackingMode] = useState(null);
    const [prePackagedType, setPrePackagedType] = useState(null);
    const [storageRequirement, setStorageRequirement] = useState(null);
    const [otherStorageRequirement, setOtherStorageRequirement] = useState(null);
    const [productCategory, setProductCategory] = useState(null);
    const [initialImport, setInitialImport] = useState("No");
    
    // Test Information
    const [testInfo, setTestInfo] = useState([{procedRef : '', testRef : '', methodRef : '', note : '', unit : ''}]);
    const [testInfoErrMsg, setTestInfoErrMsg] = useState(['']);

    const ResponseMessageField = () => {
        return (
            <div ref={responseMsgSectionRef} className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mb-4`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                    </div>
                    <div className="ml-3">
                        <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                    </div>
                </div>
            </div>
        )
        
    }

    const spliceFromTestInfoArray = (index) => {
        if(testInfo && testInfo.length === 1) { return; }

        let tempTestInfoArray = [...testInfo];
        tempTestInfoArray.splice(index, 1);
        setTestInfo(tempTestInfoArray);

        let tempTestInfoErrMsgArray = [...testInfoErrMsg];
        tempTestInfoErrMsgArray.splice(index, 1);
        setTestInfoErrMsg(tempTestInfoErrMsgArray);
    }

    const pushIntoTestInfoArray = () => {
        setTestInfo([...testInfo, {procedRef : '', testRef : '', methodRef : '', note : '', unit : ''}]);
        setTestInfoErrMsg([...testInfoErrMsg, '']);
        bottomDivRef.current?.scrollIntoView();
    }

    const setInputTestInfo = (index, value, type) => {
        let tempTestInfoArray = [...testInfo];
        let item = productCategoryTests.findIndex(object => {
            return object.TestRef === value;
        });

        switch(type) {
            case 'test':
                if (item >=0 && tempTestInfoArray.some(t => t.testRef === productCategoryTests[item].TestRef && t.procedRef === productCategoryTests[item].ProcedRef)) {
                    let tempTestInfoErrMsg = [...testInfoErrMsg];
                    tempTestInfoErrMsg[index] = '* Test is duplicated.';
                    setTestInfoErrMsg(tempTestInfoErrMsg);
                }
                else {
                    let tempTestInfoErrMsg = [...testInfoErrMsg];
                    tempTestInfoErrMsg[index] = '';
                    setTestInfoErrMsg(tempTestInfoErrMsg);
                }

                tempTestInfoArray[index].procedRef = item >=0 ? productCategoryTests[item].ProcedRef : '';
                tempTestInfoArray[index].testRef = item >=0 ? productCategoryTests[item].TestRef : '';
                tempTestInfoArray[index].methodRef = item >=0 ? productCategoryTests[item].MethodRef : '';
                tempTestInfoArray[index].unit = item >=0 ? productCategoryTests[item].Units : '';
                break;
            case 'note':
                tempTestInfoArray[index].note = value;
                break;
        }
        setTestInfo(tempTestInfoArray);
    }

    const getSampleSubmissionDetails = async (sampleSubmissionId) => {
        setLoading(true);
        await api.get('/access/sample-submission-details', { params: { sampleSubmissionId: sampleSubmissionId } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;

                        // Client Details
                        setCompanyName(details.clientDetails.COMPANY_NAME);
                        setClientReference(details.clientDetails.CLIENT_REF);
                        setAddress(details.clientDetails.CL_ADDRESS);
                        setPhone(details.clientDetails.CL_PHONE);
                        setQuoteNo(details.clientDetails.QUOTE);
                        setOrderNo(details.clientDetails.ORDER);
                        setContactPerson(details.clientDetails.CONTACT_PERSON);
                        setEmail(details.clientDetails.CONTACT_EMAIL);
                        setSubmitter(details.clientDetails.SUBMITTER);
                        setSubmitterEmail(details.clientDetails.SUB_EMAIL);
                        setInvoiceTo(details.clientDetails.INV_TO);
                        setAdditionalComments(details.clientDetails.ADD_COMMENT);
                        setReportToEmail(details.clientDetails.RPTTO_EMAIL);

                        // Sample Information
                        setSampleName(details.sampleInfo.JOB_DESCRIPTION);
                        setBatchNo(details.sampleInfo.CLIENT_BATCH);
                        setProductionDate(details.sampleInfo.PDT_DATE);
                        setExpirationDate(details.sampleInfo.EXPIR_DATE);
                        setManufacturer(details.sampleInfo.MANUFACTURER);
                        setApprovalNo(details.sampleInfo.APPROVAL_NO);
                        setOriginPlace(details.sampleInfo.POO);
                        setImporter(details.sampleInfo.IMPOTER);
                        setQuantity(details.sampleInfo.QUANTITY);
                        setWeightUnit(details.sampleInfo.WEIGHT_UNIT);
                        setSampleColor(details.sampleInfo.SAMPLE_COLOR);
                        setSampleForm(details.sampleInfo.SAMPLE_FORM);
                        setUnitId(details.sampleInfo.UNIT_ID);
                        setPackingMode(details.sampleInfo.PACK_MODE);
                        setPrePackagedType(details.sampleInfo.PREPACK_TYPE);
                        setStorageRequirement(details.sampleInfo.STOR_REQUIRE);
                        setOtherStorageRequirement(details.sampleInfo.OSTOR_REQUIRE);
                        setProductCategory(details.sampleInfo.PRDCT_CATEGORY);
                        setInitialImport(details.sampleInfo.INI_IMPORT ? details.sampleInfo.INI_IMPORT : 'No');

                        //Test Information
                        let tempTestInfoArray = [];
                        let tempTestInfoErrMsgArray = [];
                        details.testInfo.forEach((t, i) => {
                            tempTestInfoArray.push({procedRef : t.ProcedRef, testRef : t.TestRef,  methodRef : t.MethodRef, note : t.Note, unit : t.Units});
                            tempTestInfoErrMsgArray.push('');
                        });
                        if(tempTestInfoArray.length === 0) { tempTestInfoArray.push({procedRef : '', testRef : '',  methodRef : '', note : '', unit : ''}); }
                        if(tempTestInfoErrMsgArray.length === 0) { tempTestInfoErrMsgArray.push(''); }
                        setTestInfo(tempTestInfoArray);
                        setTestInfoErrMsg(tempTestInfoErrMsgArray); // same length as testInfo array
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Somthing went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getProductCategoryTests = async () => {
        setLoading(true);
        await api.get('/access/sample-submission-tests')
                .then(res => {
                    if(res.data.success) { 
                        setProductCategoryTests(res.data.data);
                    }else {
                        setProductCategoryTests(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const submitSampleSubmission = async () => {

        let validationMsgArray = [];
        if(!companyName) {
            validationMsgArray.push('* Company Name is required.');
        }
        if(!contactPerson) {
            validationMsgArray.push('* Contact Person is required.');
        }
        if(!email) {
            validationMsgArray.push('* Email is required.');
        }
        if(submitterEmail && submitterEmail.length == 0) {
            validationMsgArray.push('* Submitter Email is required.');
        }
        if(!sampleName) {
            validationMsgArray.push('* Name of Sample is required.');
        }
        if(!batchNo) {
            validationMsgArray.push('* Batch No. is required.');
        }
        if(!packingMode) {
            validationMsgArray.push('* Modes of Packing is required.');
        }
        else if(packingMode === 'Pre-Packed') {
            if(!prePackagedType) {
                validationMsgArray.push('* Please select pre-packaged type.');
            }
        }
        if(!storageRequirement) {
            validationMsgArray.push('* Storage requirement is required.');
        }
        if(!productCategory) {
            validationMsgArray.push('* Product category is required.');
        }
        if(!testInfo[0].testRef) {
            validationMsgArray.push('* Please select a test.');
        }
        if(testInfo.some(value => value.testRef === null || value.testRef === undefined || value.testRef === '')) {
            validationMsgArray.push('* Please remove empty test field.');
        }
        if(testInfoErrMsg.length > 0 && testInfoErrMsg.some(value => value !== null && value !== undefined && value !== '')) {
            validationMsgArray.push(testInfoErrMsg.toString().split(',').join(''));
        }


        if(validationMsgArray.length > 0) {
            setResponseStatus(false);
            setResponseMsg(validationMsgArray.toString());
            setShowResponseMsg(true);
            if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
            return;
        }

        let submissionFormData = {
            submissionId : selectedRow ? selectedRow.Id : null,
            clientDetails : {
                companyName : companyName ? companyName.toString() : null,
                clientRef: clientReference ? clientReference.toString() : null,
                address : address ? address.toString() : null,
                phone : phone ? phone.toString() : null,
                quoteNo : quoteNo ? quoteNo.toString() : null,
                orderNo : orderNo ? orderNo.toString() : null,
                contactPerson : contactPerson ? contactPerson.toString() : null,
                email : email ? email.toString() : null,
                submitter : submitter ? submitter.toString() : null,
                submitterEmail : submitterEmail,
                invoiceTo : invoiceTo ? invoiceTo.toString() : null,
                additionalComments : additionalComments ? additionalComments.toString() : null,
                reportToEmail : reportToEmail ? reportToEmail.toString() : null
            },
            sampleInfo : {
                sampleName : sampleName ? sampleName.toString() : null,
                batchNo : batchNo ? batchNo.toString() : null,
                productionDate : productionDate ? productionDate.toString() : null,
                expirationDate : expirationDate ? expirationDate.toString() : null,
                manufacturer : manufacturer ? manufacturer.toString() : null,
                approvalNo : approvalNo ? approvalNo.toString() : null,
                originPlace : originPlace ? originPlace.toString() : null,
                importer : importer ? importer.toString() : null,
                quantity : quantity ? quantity.toString() : null,
                weightUnit : weightUnit ? weightUnit.toString() : null,
                sampleColor : sampleColor ? sampleColor.toString() : null,
                sampleForm : sampleForm ? sampleForm.toString() : null,
                unitId : unitId ? unitId.toString() : null,
                packingMode : packingMode ? packingMode.toString() : null,
                prePackagedType : prePackagedType ? prePackagedType.toString() : null,
                storageRequirement : storageRequirement ? storageRequirement.toString() : null,
                otherStorageRequirement : otherStorageRequirement ? otherStorageRequirement.toString() : null,
                productCategory : productCategory ? productCategory.toString() : null,
                initialImport : initialImport ? initialImport.toString() : null,
            },
            testInfo : testInfo,
            readTerms : true
        }

        setLoading(true);
        setResponseMsg(null);
        setResponseStatus(null);
        setShowResponseMsg(false);

        if(selectedRow) {
            await api.put('/access/edit-sample-submission', { 
                    sampleSubmissionId : submissionFormData.submissionId,
                    clientDetails : submissionFormData.clientDetails, 
                    sampleInfo : submissionFormData.sampleInfo, 
                    testInfo : submissionFormData.testInfo,
                    readTerms : submissionFormData.readTerms
                })
                .then(res => {
                    if(res.data.success) {
                        setResponseMsg(res.data.message);
                        setResponseStatus(true);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                })
                .finally(() => {
                    setShowResponseMsg(true);
                });
        }
        else {
            await api.post('/access/create-sample-submission', { 
                    clientDetails : submissionFormData.clientDetails, 
                    sampleInfo : submissionFormData.sampleInfo, 
                    testInfo : submissionFormData.testInfo,
                    readTerms : submissionFormData.readTerms
                })
                .then(res => {
                    if(res.data.success) { 
                        setSampleSubmissionList(res.data.data);
                        setShowSubmissionFormDialog(false);
                    };
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                })
                .finally(() => {
                    
                });
        }
        setLoading(false);
    }

    const getSubmissionTemplateList = async () => {
        setLoading(true);
        await api.get('/access/active-submission-templates')
                .then(res => {
                    if(res.data.success) { 
                        setSubmissionTemplateList(res.data.data);
                    }else {
                        setSubmissionTemplateList(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        console.log(JSON.stringify(err.response.data.message));
                    }
                });
        setLoading(false);
    }

    const getSubmissionTemplateDetails = async (submissionTemplateId) => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/access/active-submission-template-details', { params: { templateId: submissionTemplateId } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;

                        // Client Details
                        setCompanyName(details.clientDetails.COMPANY_NAME);
                        setClientReference(details.clientDetails.CLIENT_REF);
                        setAddress(details.clientDetails.CL_ADDRESS);
                        setPhone(details.clientDetails.CL_PHONE);
                        setQuoteNo(details.clientDetails.QUOTE);
                        setOrderNo(details.clientDetails.ORDER);
                        setContactPerson(details.clientDetails.CONTACT_PERSON);
                        setEmail(details.clientDetails.CONTACT_EMAIL);
                        setSubmitter(details.clientDetails.SUBMITTER);
                        setSubmitterEmail(details.clientDetails.SUB_EMAIL);
                        setInvoiceTo(details.clientDetails.INV_TO);
                        setAdditionalComments(details.clientDetails.ADD_COMMENT);

                        // Sample Information
                        setSampleName(details.sampleInfo.JOB_DESCRIPTION);
                        setBatchNo(details.sampleInfo.CLIENT_BATCH);
                        setProductionDate(details.sampleInfo.PDT_DATE);
                        setExpirationDate(details.sampleInfo.EXPIR_DATE);
                        setManufacturer(details.sampleInfo.MANUFACTURER);
                        setApprovalNo(details.sampleInfo.APPROVAL_NO);
                        setOriginPlace(details.sampleInfo.POO);
                        setImporter(details.sampleInfo.IMPOTER);
                        setQuantity(details.sampleInfo.QUANTITY);
                        setWeightUnit(details.sampleInfo.WEIGHT_UNIT);
                        setSampleColor(details.sampleInfo.SAMPLE_COLOR);
                        setSampleForm(details.sampleInfo.SAMPLE_FORM);
                        setUnitId(details.sampleInfo.UNIT_ID);
                        setPackingMode(details.sampleInfo.PACK_MODE);
                        setPrePackagedType(details.sampleInfo.PREPACK_TYPE);
                        setStorageRequirement(details.sampleInfo.STOR_REQUIRE);
                        setOtherStorageRequirement(details.sampleInfo.OSTOR_REQUIRE);
                        setProductCategory(details.sampleInfo.PRDCT_CATEGORY);
                        setInitialImport(details.sampleInfo.INI_IMPORT ? details.sampleInfo.INI_IMPORT : 'No');

                        //Test Information
                        let tempTestInfoArray = [];
                        let tempTestInfoErrMsgArray = [];
                        details.testInfo.forEach((t, i) => {
                            tempTestInfoArray.push({procedRef : t.ProcedRef, testRef : t.TestRef,  methodRef : t.MethodRef, note : t.Note, unit : t.Units});
                            tempTestInfoErrMsgArray.push('');
                        });
                        if(tempTestInfoArray.length === 0) { tempTestInfoArray.push({procedRef : '', testRef : '',  methodRef : '', note : '', unit : ''}); }
                        if(tempTestInfoErrMsgArray.length === 0) { tempTestInfoErrMsgArray.push(''); }
                        setTestInfo(tempTestInfoArray);
                        setTestInfoErrMsg(tempTestInfoErrMsgArray); // same length as testInfo array
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message)
                    {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const upsertSubmissionTemplate = async () => {
        let validationMsgArray = [];
        if(!sampleName) {
            validationMsgArray.push('* Name of Sample is required.');
        }

        if(!testInfo[0].testRef) {
            validationMsgArray.push('* Please select a test.');
        }
        if(testInfo.some(value => value.testRef === null || value.testRef === undefined || value.testRef === '')) {
            validationMsgArray.push('* Please remove empty test field.');
        }
        if(testInfoErrMsg.length > 0 && testInfoErrMsg.some(value => value !== null && value !== undefined && value !== '')) {
            validationMsgArray.push(testInfoErrMsg.toString().split(',').join(''));
        }

        if(validationMsgArray.length > 0) {
            setResponseStatus(false);
            setResponseMsg(validationMsgArray.toString());
            setShowResponseMsg(true);
            if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
            return;
        }

        let submissionFormData = {
            templateId : submissionTemplateId,
            clientDetails : {
                companyName : companyName ? companyName.toString() : null,
                clientRef: clientReference ? clientReference.toString() : null,
                address : address ? address.toString() : null,
                phone : phone ? phone.toString() : null,
                quoteNo : quoteNo ? quoteNo.toString() : null,
                orderNo : orderNo ? orderNo.toString() : null,
                contactPerson : contactPerson ? contactPerson.toString() : null,
                email : email ? email.toString() : null,
                submitter : submitter ? submitter.toString() : null,
                submitterEmail : submitterEmail,
                invoiceTo : invoiceTo ? invoiceTo.toString() : null,
                additionalComments : additionalComments ? additionalComments.toString() : null
            },
            sampleInfo : {
                sampleName : sampleName ? sampleName.toString() : null,
                batchNo : batchNo ? batchNo.toString() : null,
                productionDate : productionDate ? productionDate.toString() : null,
                expirationDate : expirationDate ? expirationDate.toString() : null,
                manufacturer : manufacturer ? manufacturer.toString() : null,
                approvalNo : approvalNo ? approvalNo.toString() : null,
                originPlace : originPlace ? originPlace.toString() : null,
                importer : importer ? importer.toString() : null,
                quantity : quantity ? quantity.toString() : null,
                weightUnit : weightUnit ? weightUnit.toString() : null,
                sampleColor : sampleColor ? sampleColor.toString() : null,
                sampleForm : sampleForm ? sampleForm.toString() : null,
                unitId : unitId ? unitId.toString() : null,
                packingMode : packingMode ? packingMode.toString() : null,
                prePackagedType : prePackagedType ? prePackagedType.toString() : null,
                storageRequirement : storageRequirement ? storageRequirement.toString() : null,
                otherStorageRequirement : otherStorageRequirement ? otherStorageRequirement.toString() : null,
                productCategory : productCategory ? productCategory.toString() : null,
                initialImport : initialImport ? initialImport.toString() : null,
            },
            testInfo : testInfo
        }

        setLoading(true);
        setResponseMsg(null);
        setResponseStatus(null);
        setShowResponseMsg(false);

        await api.post('/access/save-submission-template', { 
                templateId : submissionFormData.templateId,
                clientDetails : submissionFormData.clientDetails, 
                sampleInfo : submissionFormData.sampleInfo, 
                testInfo : submissionFormData.testInfo,
            })
            .then(res => {
                if(res.data.success) {
                    setSubmissionTemplateList(res.data.data);
                    setResponseMsg(res.data.message);
                    setResponseStatus(true);
                };
            })
            .catch(err => {
                if(err && err.response && err.response.data && err.response.data.message) {
                    setResponseMsg(JSON.stringify(err.response.data.message));
                }
                else {
                    setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                }
                setResponseStatus(false);
            })
            .finally(() => {
                setShowResponseMsg(true);
            });

        setLoading(false);
    }

    useEffect(() => {
        if(selectedRow) { getSampleSubmissionDetails(selectedRow.Id); }
        else { 
            getSubmissionTemplateList();
            setCompanyName('ALS Food & Environmental Hamilton');
            setAddress('c/o Analytica Laboratories Ltd, 10 Bisley Road, Hamilton 3214');
            setEmail('samples_nz-analytica@alsglobal.com');
            setSubmitter('Analytica Results');
            setSubmitterEmail(['samples_nz-analytica@alsglobal.com']);
            // Sample Information
            setPackingMode('Bulk');
            setStorageRequirement("Room Temperature");
            setProductCategory("9");
            // Special from other client
            setReportToEmail('ALSFood.Hamilton@alsglobal.com');
        }
        getProductCategoryTests();
    }, []);

    useEffect(() => {
        setSampleName(clientReference);
        setBatchNo(clientReference);
    }, [clientReference]);

    useEffect(() => {
        if(submissionTemplateId) { getSubmissionTemplateDetails(submissionTemplateId); }
    }, [submissionTemplateId]);

    useEffect(() => {
        if(historicalSubmissionId) { getSampleSubmissionDetails(historicalSubmissionId); }
    }, [historicalSubmissionId]);

    useEffect(() => {
        if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
    }, [responseStatus, responseMsg, showResponseMsg]);

    return (
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-3/5 mx-auto">
                <div className="py-5 px-16 flex justify-between bg-gray-100 rounded-t-lg">
                    {selectedRow && <div className="w-1/3 text-left text-sm mt-3 -mb-3">Batch No.: {selectedRow.BatchNo}</div>}
                    {!selectedRow && <div className="flex text-gray-900 text-left text-sm w-1/3 mt-3 -mb-3">
                        <label className="block text-md font-medium text-gray-700 mt-1">Template</label>
                        <select className="ml-3 py-1 px-4 block w-2/3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={ submissionTemplateId || ''}
                            onChange={e => { setSubmissionTemplateId(e.target.value) }}
                        >
                            <option className="text-gray-400" value=''>None</option>
                            {submissionTemplateList && submissionTemplateList.length > 0 &&
                                submissionTemplateList.map((template, index) => (
                                    <option key={index} className="text-gray-800" value={template.TemplateId}>{template.TemplateName}</option>
                            ))} 
                        </select>
                    </div>}
                    <div className="text-gray-900 text-center font-semibold w-1/3">
                        Sample Submission Form
                    </div>
                    {!selectedRow && <div className="flex text-gray-900 text-right text-sm w-1/3 mt-3 -mb-3">
                        <label className="block text-md font-medium text-gray-700 mt-1 ml-5">History</label>
                        <select className="ml-3 py-1 px-4 block w-2/3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={ historicalSubmissionId }
                            onChange={e => { setHistoricalSubmissionId(e.target.value) }}
                        >
                            <option className="text-gray-400" value=''>None</option>
                            {sampleSubmissionList && sampleSubmissionList.length > 0 &&
                                sampleSubmissionList.map((sub, index) => (
                                    <option key={index} className="text-gray-800" value={sub.Id}>{sub.SampleName}</option>
                                ))} 
                        </select>
                    </div>}
                    {selectedRow && <div className="w-1/3"></div>}
                </div>
                <div className="flex shadow bg-white max-h-630 h-630">
                    <div className="px-10 py-5 w-full overflow-y-auto">
                        {showResponseMsg && <ResponseMessageField />}
                        <div className="mt-2">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Client Details</div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Company Name
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={companyName || ''}
                                            onChange={e => { setCompanyName(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!companyName && <p className="text-red-500 text-xs italic mt-2">* Campany name is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Phone
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={phone || ''}
                                        onChange={e => { setPhone(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-6 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Address
                                </label>
                                <div className="w-5/6">
                                    <input
                                        value={address || ''}
                                        onChange={e => { setAddress(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Client Reference
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={clientReference || ''}
                                            onChange={e => { setClientReference(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!clientReference && <p className="text-red-500 text-xs italic mt-2">* Client Reference is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Purchase Order #
                                </label>
                                <div className="w-1/2">
                                    <input
                                        value={orderNo || ''}
                                        onChange={e => { setOrderNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                {/* Contact Person */}
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Contact Person
                                    </label>
                                    <div className="w-2/3">
                                        <textarea
                                            rows={2}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={contactPerson || ''}
                                            onChange={e => { setContactPerson(e.target.value); }}
                                        />
                                    </div>
                                </div>
                                {!contactPerson && <p className="text-red-500 text-xs italic mt-2">* Contact person is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    E-mail Report
                                </label>
                                <div className="w-3/5">
                                    <input
                                        value={reportToEmail || ''}
                                        onChange={e => { setReportToEmail(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-6 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Comments /<br/>Special Instructions
                                    </label>
                                    <div className="w-3/4">
                                        <textarea
                                            rows={3}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={additionalComments || ''}
                                            onChange={e => { setAdditionalComments(e.target.value); }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="flex col-span-2 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Quote #
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={quoteNo || ''}
                                        onChange={e => { setQuoteNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Email
                                    </label>
                                    <div className="w-3/4">
                                        <textarea
                                            rows={4}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={email || ''}
                                            onChange={e => {setEmail(e.target.value)}}
                                        />
                                    </div>
                                </div>
                                {!email && <p className="text-red-500 text-xs italic mt-2">* Email is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Submitter (if not client)
                                </label>
                                <div className="w-5/12">
                                    <input
                                        value={submitter || ''}
                                        onChange={e => { setSubmitter(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Invoice To (if not client)
                                </label>
                                <div className="w-5/12">
                                    <input
                                        value={invoiceTo || ''}
                                        onChange={e => { setInvoiceTo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Submitter Email
                                    </label>
                                    <div className="w-2/3 ml-5">
                                        <ReactMultiEmail
                                            emails={submitterEmail}
                                            onChange={_emails => { setSubmitterEmail(_emails); }}
                                            getLabel={(email, index, removeEmail) => {
                                                return (
                                                  <div data-tag key={index}>
                                                    <div data-tag-item>{email}</div>
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                                      ×
                                                    </span>
                                                  </div>
                                                );
                                              }}
                                        />
                                    </div>
                                </div>
                                {submitterEmail && submitterEmail.length == 0 && <p className="text-red-500 text-xs italic mt-2">* Submitter email is required.</p>}
                            </div>  */}
                        </div>
                        
                        {/* <div className="mt-8">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Sample's Information</div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Name of Sample
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={sampleName || ''}
                                            onChange={e => { setSampleName(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!sampleName && <p className="text-red-500 text-xs italic mt-2">* Name of sample is required.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Batch No.
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={batchNo || ''}
                                            onChange={e => { setBatchNo(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!batchNo && <p className="text-red-500 text-xs italic mt-2">* Batch number is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-2">
                                    Production Date
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={productionDate || ''}
                                        onChange={e => { setProductionDate(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-2">
                                    Expiration Date
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={expirationDate || ''}
                                        onChange={e => { setExpirationDate(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Manufacturer
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={manufacturer || ''}
                                        onChange={e => { setManufacturer(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Approval No.
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={approvalNo || ''}
                                        onChange={e => { setApprovalNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Place of Origin
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={originPlace || ''}
                                        onChange={e => { setOriginPlace(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Importer
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={importer || ''}
                                        onChange={e => { setImporter(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Quantity
                                    </label>
                                    <div className="w-2/3">
                                        <textarea
                                            rows={2}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={quantity || ''}
                                            onChange={e => setQuantity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {!quantity && <p className="text-red-500 text-xs italic mt-2">* Quantity is required.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Weight / Unit
                                    </label>
                                    <div className="w-2/3">
                                        <textarea
                                            rows={2}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={weightUnit || ''}
                                            onChange={e => { setWeightUnit(e.target.value); }}
                                        />
                                    </div>
                                </div>
                                {!weightUnit && <p className="text-red-500 text-xs italic mt-2">* Weight/Unit is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Sample Color
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={sampleColor || ''}
                                        onChange={e => { setSampleColor(e.target.value) }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Unit ID
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={unitId || ''}
                                        onChange={e => { setUnitId(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Sample Form
                                </label>
                                <div className="w-1/2">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={sampleForm || ''}
                                        onChange={e => { setSampleForm(e.target.value) }}>
                                        <option value=""></option>
                                        <option value="Powder">Powder</option>
                                        <option value="Liquid">Liquid</option>
                                        <option value="Solid">Solid</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Modes of Packing
                                    </label>
                                    <div className="w-1/3">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={packingMode || ''}
                                            onChange={e => { setPackingMode(e.target.value); setPrePackagedType(null); }}>
                                            <option value=""></option>
                                            <option value="Pre-Packed">Pre-Packed</option>
                                            <option value="Bulk">Bulk</option>
                                        </select>
                                    </div>
                                    {packingMode === "Pre-Packed" && <div className="w-1/4 ml-2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={prePackagedType || ''}
                                            onChange={e => { setPrePackagedType(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="Sealed">Sealed</option>
                                            <option value="Unsealed">Unsealed</option>
                                        </select>
                                    </div>}
                                </div>
                                {!packingMode && <p className="text-red-500 text-xs italic mt-2">* Modes of Packing is required.</p>}
                                {(packingMode === "Pre-Packed" && !prePackagedType) && <p className="text-red-500 text-xs italic mt-2">* Please select pre-packaged type.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Storage Requirement
                                    </label>
                                    <div className="w-1/2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={storageRequirement || ''}
                                            onChange={e => { setStorageRequirement(e.target.value); setOtherStorageRequirement(null); }}>
                                            <option value=""></option>
                                            <option value="Room Temperature">Room Temperature</option>
                                            <option value="Cold Storage">Cold Storage</option>
                                            <option value="Freeze">Freeze</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {storageRequirement === "Other" && <div className="w-1/4 ml-2">
                                        <input
                                            value={otherStorageRequirement || ''}
                                            onChange={e => { setOtherStorageRequirement(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>}
                                </div>
                                {!storageRequirement && <p className="text-red-500 text-xs italic mt-2">* Storage requirement is required.</p>}
                                {(storageRequirement === "Other" && !otherStorageRequirement) && <p className="text-red-500 text-xs italic mt-2">* Please enter other storeage requirement.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Initial Import
                                </label>
                                <div className="w-1/2">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={initialImport || ''}
                                        onChange={e => { setInitialImport(e.target.value) }}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Product Category
                                    </label>
                                    <div className="w-1/2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={productCategory || ''}
                                            onChange={e => { setProductCategory(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="1">Infant Formula Stage 1</option>
                                            <option value="2">Infant Formula Stage 2</option>
                                            <option value="3">Infant Formula Stage 3</option>
                                            <option value="4">Infant Formula Child</option>
                                            <option value="5">Milk Power</option>
                                            <option value="6">Liquid Milk</option>
                                            <option value="7">Whey Powder</option>
                                            <option value="8">Cheese</option>
                                            <option value="9">Honey</option>
                                            <option value="10">Yoghurt</option>
                                            <option value="11">Cream</option>
                                            <option value="12">Base Powder</option>
                                            <option value="13">Pet Food</option>
                                            <option value="14">UHT Milk</option>
                                            <option value="0">Other</option>
                                        </select>
                                    </div>
                                </div>
                                {!productCategory && <p className="text-red-500 text-xs italic mt-2">* Product category is required.</p>}
                            </div>
                        </div> */}

                        {/* Test information */}
                        <div className="mt-8 flex">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Test Information</div>
                            <div className='mx-2 mt-3 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                onClick={ () => { setTestInfo([{procedRef : '',  testRef : '', methodRef : '', note : '', unit : ''}]); setTestInfoErrMsg(['']); } }
                                >Clear all tests
                            </div>
                        </div>

                        {testInfo && testInfo.length > 0 &&
                            testInfo.map((item, i) => (
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" key={i}>
                                    <div className="col-span-6 mx-2">
                                        <div className="flex">
                                            <div className="mt-1 cursor-pointer"
                                                onClick={() => pushIntoTestInfoArray() }>
                                                {getPlusIcon()}
                                            </div>
                                            <div className="w-1/2 mx-2">
                                                <input
                                                    value={item.note || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'note') }}
                                                    type="text"
                                                    placeholder="Sample ID"
                                                    className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                />
                                            </div>
                                            <div className="w-2/3 mx-2">
                                                <select className={`${!item.testRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                    value={item.testRef || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'test') }}>
                                                    <option className="text-gray-400" value="">Test Item</option>
                                                    {productCategoryTests && productCategoryTests.length > 0 &&
                                                        productCategoryTests.map((obj, index) => (
                                                            <option key={index} className="text-gray-800" value={obj.TestRef}>{obj.TestDescrip}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="w-2/3 mx-2">
                                                <select className={`${!item.methodRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                    value={item.methodRef || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'method') }}
                                                    //disabled={!item.testRef}>
                                                    disabled={true}>
                                                    <option className="text-gray-300" value="">Test Method</option>
                                                    {productCategoryTests && productCategoryTests.length > 0 &&
                                                        productCategoryTests.map((obj, index) => (
                                                            obj.TestRef === item.testRef ?
                                                            <option key={index} className="text-gray-800" value={obj.MethodRef}>{obj.MethodDescrip}</option>
                                                            :
                                                            <></>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="w-1/3 mx-2">
                                                <input
                                                    value={item.unit || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'unit') }}
                                                    type="text"
                                                    placeholder="Unit"
                                                    disabled={true}
                                                    className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                />
                                            </div>
                                            <div className="ml-4 mt-1 cursor-pointer"
                                                onClick={() => spliceFromTestInfoArray(i) }>
                                                {getMinusIcon()}
                                            </div>
                                        </div>
                                        {!testInfo[i].testRef && <p className="text-red-500 text-xs italic mt-2">* Please select a test.</p>}
                                        {testInfoErrMsg[i] && <p className="text-red-500 text-xs italic mt-2">{ testInfoErrMsg[i] }</p>}
                                    </div>
                                </div>))
                        }
                        <div ref={bottomDivRef} className="mt-16"></div>
                    </div>
                </div>

                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ setShowSubmissionFormDialog(false); setSelectedRow(null); }} type="button" className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>      
                    </div>

                    <div className="flex justify-between">
                        {selectedRow && <div className="px-6 py-3">
                            <button 
                                onClick={()=>{ downloadSampleSubmissionForm(selectedRow); }} 
                                type="button" 
                                className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                disabled={loading}
                            >
                                { loading ? <div className="px-6">{getSpinnerIcon()}</div> : 'Download' }
                            </button>
                        </div>}

                        {!selectedRow && <div className="px-6 py-3">
                            <button 
                                onClick={()=>{ upsertSubmissionTemplate(submissionTemplateId); }} 
                                type="button" 
                                className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                disabled={loading}
                            >
                                { loading ? <div className="px-2">{getSpinnerIcon()}</div> : 'Save' }
                            </button>
                        </div>}

                        {((selectedRow && selectedRow.Status !== "Accepted" && selectedRow.Status !== "Cancelled") || !selectedRow) &&
                            <div className="px-6 py-3 text-xs font-medium text-gray-500">
                                <button 
                                    onClick={()=>{ submitSampleSubmission(); }} 
                                    type="button" 
                                    className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                    disabled={loading}
                                >
                                    { loading ? <div className="px-3">{getSpinnerIcon()}</div> : 'Submit' }
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalyticaSampleSubmissionForm;